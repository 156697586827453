/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { equals as t } from "./arrayUtils.js";
import { isInt8Array as n, isUint8Array as e, isUint8ClampedArray as r, isInt16Array as o, isUint16Array as f, isInt32Array as i, isUint32Array as u, isFloat32Array as c, isFloat64Array as s } from "./typedArrayUtil.js";
function l(t, n) {
  let e;
  if (n) for (e in t) t.hasOwnProperty(e) && (void 0 === t[e] ? delete t[e] : t[e] instanceof Object && l(t[e], !0));else for (e in t) t.hasOwnProperty(e) && void 0 === t[e] && delete t[e];
  return t;
}
function a(t) {
  if (!t || "object" != typeof t || "function" == typeof t) return t;
  const n = O(t);
  if (null != n) return n;
  if (y(t)) return t.clone();
  if (m(t)) return t.map(a);
  if (b(t)) return t.clone();
  const e = {};
  for (const r of Object.getOwnPropertyNames(t)) e[r] = a(t[r]);
  return e;
}
function p(t) {
  if (!t || "object" != typeof t || "function" == typeof t || "HTMLElement" in globalThis && t instanceof HTMLElement) return t;
  const n = O(t);
  if (null != n) return n;
  if (m(t)) {
    let n = !0;
    const e = t.map(t => {
      const e = p(t);
      return null != t && null == e && (n = !1), e;
    });
    return n ? e : null;
  }
  if (y(t)) return t.clone();
  if (t instanceof File || t instanceof Blob) return t;
  if (!b(t)) {
    const n = new (0, Object.getPrototypeOf(t).constructor)();
    for (const e of Object.getOwnPropertyNames(t)) {
      const r = t[e],
        o = p(r);
      if (null != r && null == o) return null;
      n[e] = o;
    }
    return n;
  }
  return null;
}
function y(t) {
  return "function" == typeof t.clone;
}
function m(t) {
  return "function" == typeof t.map && "function" == typeof t.forEach;
}
function b(t) {
  return "function" == typeof t.notifyChange && "function" == typeof t.watch;
}
function g(t) {
  if ("[object Object]" !== Object.prototype.toString.call(t)) return !1;
  const n = Object.getPrototypeOf(t);
  return null === n || n === Object.prototype;
}
function O(t) {
  if (n(t) || e(t) || r(t) || o(t) || f(t) || i(t) || u(t) || c(t) || s(t)) return t.slice();
  if (t instanceof Date) return new Date(t.getTime());
  if (t instanceof ArrayBuffer) {
    return t.slice(0, t.byteLength);
  }
  if (t instanceof Map) {
    const n = new Map();
    for (const [e, r] of t) n.set(e, a(r));
    return n;
  }
  if (t instanceof Set) {
    const n = new Set();
    for (const e of t) n.add(a(e));
    return n;
  }
  return null;
}
function j(t, n) {
  return t === n || "number" == typeof t && isNaN(t) && "number" == typeof n && isNaN(n) || "function" == typeof t?.getTime && "function" == typeof n?.getTime && t.getTime() === n.getTime() || !1;
}
function h(n, e) {
  return n === e || (null == n || "string" == typeof n ? n === e : "number" == typeof n ? n === e || "number" == typeof e && isNaN(n) && isNaN(e) : n instanceof Date ? e instanceof Date && n.getTime() === e.getTime() : Array.isArray(n) ? Array.isArray(e) && t(n, e) : n instanceof Set ? e instanceof Set && N(n, e) : n instanceof Map ? e instanceof Map && T(n, e) : !!g(n) && g(e) && w(n, e));
}
function w(t, n) {
  if (null === t || null === n) return !1;
  const e = Object.keys(t);
  if (null === n || Object.keys(n).length !== e.length) return !1;
  for (const r of e) if (t[r] !== n[r] || !Object.prototype.hasOwnProperty.call(n, r)) return !1;
  return !0;
}
function N(t, n) {
  if (t.size !== n.size) return !1;
  for (const e of t) if (!n.has(e)) return !1;
  return !0;
}
function T(t, n) {
  if (t.size !== n.size) return !1;
  for (const [e, r] of t) {
    const t = n.get(e);
    if (t !== r || void 0 === t && !n.has(e)) return !1;
  }
  return !0;
}
export { a as clone, j as equals, h as equalsShallow, l as fixJson, b as isAccessorLike, y as isClonable, g as isPlainObject, p as tryClone };