/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { clone as r } from "./lang.js";
function n(r, n, t = !1) {
  return o(r, n, t);
}
function t(r, n) {
  if (null != n) return n[r] || i(r.split("."), !1, n);
}
function e(r, n, t) {
  const e = r.split("."),
    u = e.pop(),
    o = i(e, !0, t);
  o && u && (o[u] = n);
}
function u(r, n) {
  if (null == r && null == n) return !1;
  if (null == r) return !0;
  if (null == n) return !0;
  if ("object" == typeof r) {
    if (Array.isArray(r)) {
      const t = n;
      if (r.length !== t.length) return !0;
      for (let n = 0; n < r.length; n++) if (u(r[n], t[n])) return !0;
      return !1;
    }
    if (Object.keys(r).length !== Object.keys(n).length) return !0;
    for (const t in r) {
      if (u(r[t], n[t])) return !0;
    }
    return !1;
  }
  return r !== n;
}
function i(r, n, t) {
  let e = t;
  for (const u of r) {
    if (null == e) return;
    if (!(u in e)) {
      if (!n) return;
      e[u] = {};
    }
    e = e[u];
  }
  return e;
}
function o(n, t, e) {
  return t ? Object.keys(t).reduce((n, u) => {
    let i = n[u],
      f = t[u];
    return i === f ? n : void 0 === i ? (n[u] = r(f), n) : (Array.isArray(f) || Array.isArray(n) ? (i = i ? Array.isArray(i) ? n[u] = i.concat() : n[u] = [i] : n[u] = [], f && (Array.isArray(f) || (f = [f]), e ? f.forEach(r => {
      i.includes(r) || i.push(r);
    }) : n[u] = f.concat())) : f && "object" == typeof f ? n[u] = o(i, f, e) : n.hasOwnProperty(u) && !t.hasOwnProperty(u) || (n[u] = f), n);
  }, n || {}) : n;
}
export { n as deepMerge, t as getDeepValue, u as notDeepEqual, e as setDeepValue };