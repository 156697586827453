/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import e from "../config.js";
import "./has.js";
import { numericHash as t } from "./string.js";
const r = {
  info: 0,
  warn: 1,
  error: 2,
  none: 3
};
class n {
  constructor(e) {
    this.level = null, this._module = "", this._parent = null, this.writer = null, this._loggedMessages = {
      error: new Map(),
      warn: new Map(),
      info: new Map()
    }, null != e.level && (this.level = e.level), null != e.writer && (this.writer = e.writer), this._module = e.module, n._loggers.set(this.module, this);
    const t = this.module.lastIndexOf(".");
    -1 !== t && (this._parent = n.getLogger(this.module.slice(0, t)));
  }
  get module() {
    return this._module;
  }
  get parent() {
    return this._parent;
  }
  error(...e) {
    this._log("error", "always", ...e);
  }
  warn(...e) {
    this._log("warn", "always", ...e);
  }
  info(...e) {
    this._log("info", "always", ...e);
  }
  errorOnce(...e) {
    this._log("error", "once", ...e);
  }
  warnOnce(...e) {
    this._log("warn", "once", ...e);
  }
  infoOnce(...e) {
    this._log("info", "once", ...e);
  }
  errorOncePerTick(...e) {
    this._log("error", "oncePerTick", ...e);
  }
  warnOncePerTick(...e) {
    this._log("warn", "oncePerTick", ...e);
  }
  infoOncePerTick(...e) {
    this._log("info", "oncePerTick", ...e);
  }
  get test() {}
  static get test() {}
  static getLogger(e) {
    return e = "string" != typeof e ? e.declaredClass : e, n._loggers.get(e) || new n({
      module: e
    });
  }
  _log(t, r, ...i) {
    if (!this._matchLevel(t)) return;
    if ("always" !== r && !n._throttlingDisabled) {
      const e = o(i),
        s = this._loggedMessages[t].get(e);
      if ("once" === r && null != s || "oncePerTick" === r && s && s >= n._tickCounter) return;
      this._loggedMessages[t].set(e, n._tickCounter), n._scheduleTickCounterIncrement();
    }
    for (const n of e.log.interceptors) if (n(t, this.module, ...i)) return;
    this._inheritedWriter()(t, this.module, ...i);
  }
  _parentWithMember(e, t) {
    let r = this;
    for (; null != r;) {
      const t = r[e];
      if (null != t) return t;
      r = r.parent;
    }
    return t;
  }
  _inheritedWriter() {
    return this._parentWithMember("writer", i);
  }
  _matchLevel(t) {
    const n = e.log.level || "warn";
    return r[this._parentWithMember("level", n)] <= r[t];
  }
  static _scheduleTickCounterIncrement() {
    n._tickCounterScheduled || (n._tickCounterScheduled = !0, Promise.resolve().then(() => {
      n._tickCounter++, n._tickCounterScheduled = !1;
    }));
  }
}
function i(e, t, ...r) {
  console[e](`[${t}]`, ...r);
}
function o(...e) {
  const r = (e, t) => "object" != typeof t || Array.isArray(t) ? t : "[Object]";
  return t(JSON.stringify(e, r));
}
n._loggers = new Map(), n._tickCounter = 0, n._tickCounterScheduled = !1, n._throttlingDisabled = !1;
export { n as default };